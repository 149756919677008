<template>
  <div>
    <v-card outlined>
      <v-card-title>
        نقاط البيع
        <v-spacer></v-spacer>
        <v-btn :to="{ name: 'add-salepoint' }" color="primary">
          <v-icon> mdi-plus-circle </v-icon>
          اضافة نقطة بيع جديدة
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          
          <v-col cols="12">
            <div class="d-flex align-center mt-5">
              <v-col cols="6">
                <v-select
                  :items="provinecs"
                  v-model="options.provinecs"
                  label="المحافظة"
                  item-text="name"
                  item-value="id"
                  outlined
                  flat
                ></v-select
              >
              </v-col>

              <v-btn
                text
                large
                class="mx-2 mb-5 font-weight-dark"
                color="primary"
              >
                بحث
              </v-btn>
            </div>
            <div class="d-flex justify-content-center align-items-center mt-1">
              <GmapMap
                ref="mapRef"
                :center="{ lat: 33.312805, lng: 44.361488 }"
                :zoom="12"
                map-type-id="terrain"
                style="width: 100%; height: 50vh"
              >
                <div v-for="salePoint in salePoints" :key="salePoint.id">
                  <GmapMarker
                    ref="myMarker"
                    :position="
                      google &&
                      new google.maps.LatLng(
                        parseFloat(salePoint.latitude),
                        parseFloat(salePoint.longtude)
                      )
                    "
                  />
                </div>
              </GmapMap>
            </div>
          </v-col>
          <v-col cols="12">
            
          </v-col>
          <v-col cols="12">
            <v-data-table
disable-pagination
              :headers="headers"
              :items="salePoints"
              :no-data-text="$t('no-data')"
              hide-default-footer
              
            >
              <template #item.delete="{}">
                <v-btn color="red" small icon>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { gmapApi } from "vue2-google-maps";
export default {
  computed: {
    google: gmapApi,
  },
  data() {
    return {
      loading: true,
      options: {
        provinec: 0,
      },
      provinecs: [],
      headers: [
        {
          text: "اسم نقطة البيع",
          align: "start",
          sortable: false,
          value: "title",
        },
        {
          text: "الشركة",
          sortable: true,
          value: "companyName",
        },
        {
          text: "المحافظة",
          sortable: true,
          value: "provinecName",
        },
        {
          text: "المنطقة",
          sortable: true,
          value: "regionName",
        },

        {
          text: "",
          sortable: false,
          value: "delete",
        },
      ],
      salePoints: [],
    };
  },
  created() {
    this.$http.get("/SalePoints").then((sale) => {
      this.salePoints = sale.data.data;
      this.$http.get("/Provinecs").then((res) => {
        this.provinecs = res.data.result;
        this.provinecs.unshift({ id:'',name:"All"})
      });
    }).catch((e) => {
            this.$store.commit("UPDATE_SNACKBAR", true);
            this.$store.commit("UPDATE_SNACKBAR_MESSAGE", e.response.data.message)
          })
  },
};
</script>